


























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import {
  SalesOrderData,
  SalesOrderHeaderData,
} from "@/models/interface/salesOrder.interface";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import moment from "moment";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { masterServices } from "@/services/master.service";
import { contactServices } from "@/services/contact.service";
import { currencyFormat } from "@/validator/globalvalidator";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";

@Component
export default class SalesOrder extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  moment = moment;
  totalElements = 0 as number;
  page = 1 as number;
  limit = 10 as number;
  search = "" as string;
  searchDateParams = "" as string;
  modelForm = {
    date: [] as any,
  };
  columnsTableNested = [
    {
      title: this.$t("lbl_part_number_unit_code"),
      dataIndex: "partNumberUnitCode",
      key: "partNumberUnitCode",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "partName",
      key: "partName",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      key: "qty",
      width: 100,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      key: "price",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_vat"),
      dataIndex: "vat",
      key: "vat",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_discount"),
      dataIndex: "discount",
      key: "discount",
      width: 100,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_sub_total"),
      dataIndex: "subTotal",
      key: "subTotal",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
  ] as ColumnTableCustom;
  columnsTable = [
    {
      title: this.$t("lbl_sales_order_no"),
      dataIndex: "salesOrderNo",
      key: "salesOrderNo",
      width: 150,
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_sales_order_date"),
      dataIndex: "date",
      key: "date",
      width: 120,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currency",
      key: "currency",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchWarehouse",
      key: "branch",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "states",
      key: "states",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
  ] as any;

  form = this.$form.createForm(this, { name: "salesorder" });
  formRules = {
    salesOrderNumber: {
      label: "Sales Order Number",
      name: "salesOrderNumber",
      placeholder: "Insert salesOrderNumber",
      decorator: [
        "salesOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    date: {
      label: "Date",
      name: "Date",
      placeholder: "Insert date",
      decorator: [
        "date",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    salesType: {
      label: "Sales Type",
      name: "salesType",
      placeholder: "Insert Sales Type",
      decorator: [
        "salesType",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      label: "Status",
      name: "status",
      placeholder: "Insert Status",
      decorator: [
        "status",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  dataListSalesOrder = [] as SalesOrderData[];
  dataListSalesState = [] as ResponseListMaster[];
  dataListSalesType = [] as ResponseListMaster[];
  dataListCustomer = [] as ContactData[];
  dataSource = [] as SalesOrderHeaderData[];
  loadingSales = false as boolean;
  loadingFind = false as boolean;
  loadingDownload = false as boolean;
  loadingCustomer = false as boolean;

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  CreateNew() {
    this.$router.push({ name: "sales.sales-order.create" });
  }
  clear() {
    this.form.resetFields();
    this.modelForm.date = [];
  }

  created() {
    this.columnsTable.push({
      title: this.$root.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 75,
      align: "center",
    });
  }

  mounted() {
    this.getListSalesOrderHeaderOnly("");
    this.getSalesState();
    this.getSalesType();
    this.getCustomer("");
  }

  getCustomer(value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) {
      params.search = ` customer~true_AND_active~true_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    } else {
      params.search = ` customer~true_AND_active~true`;
    }
    this.loadingCustomer = true;
    contactServices
      .listContactData(params)
      .then(data => {
        this.dataListCustomer = data.data;
      })
      .finally(() => (this.loadingCustomer = false));
  }

  getSalesType(): void {
    let params = {
      name: "SALES_TYPE",
    } as RequestQueryParamsModel;
    masterServices.listMaster(params).then((res: any) => {
      res.map((dataObject, index) => (dataObject.key = index));
      this.dataListSalesType = res;
    });
  }

  getSalesState(): void {
    let params = {
      name: "SALES_STATE",
    } as RequestQueryParamsModel;
    masterServices.listMaster(params).then((res: any) => {
      res.map((dataObject, index) => (dataObject.key = index));
      this.dataListSalesState = res.filter(
        e => e.value != "Cancelled" && e.value != "Returned"
      );
    });
  }

  getListSalesOrderHeaderOnly(valueSearch: string) {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };

    if (valueSearch) {
      params.search = `documentNumber~*${valueSearch}*`;
    }
    this.loadingSales = true;
    salesOrderServices
      .getListSalesOrder(params)
      .then(data => {
        data.data.forEach((dataObject, index) => (dataObject.key = index));
        this.dataListSalesOrder = data.data;
      })
      .finally(() => (this.loadingSales = false));
  }

  assignSearch(key, value, operator): string {
    if (key === "salesOrderNumber" && value)
      return operator + `secureId~${value}`;
    else if (key === "customerName" && value)
      return operator + `customer.secureId~${value}`;
    else if (key === "salesType" && value)
      return operator + `salesType~${value}`;
    else if (key === "status" && value)
      return operator + `state~${value.replace(" ", "_").toUpperCase()}`;
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  async findData(pagination): Promise<void> {
    try {
      this.form.validateFields((err, res) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: pagination ? this.page - 1 : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        params.search = this.dynamicSearch(res);
        if (
          this.modelForm.date &&
          Array.isArray(this.modelForm.date) &&
          this.modelForm.date.length > 0
        ) {
          const timeFrom = this.modelForm.date?.length
            ? this.moment(this.modelForm.date[0])
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                })
                .utcOffset("+07")
                .format()
            : "";
          const timeTo = this.modelForm.date?.length
            ? this.moment(this.modelForm.date[1])
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                })
                .utcOffset("+07")
                .format()
            : "";
          const searchDate = `date>=${timeFrom}_AND_date<=${timeTo}`;
          if (params.search) {
            params.search += `_AND_${searchDate}`;
          } else {
            params.search = `${searchDate}`;
          }
        }

        this.loadingFind = true;
        salesOrderServices
          .getListSalesOrderHeader(params)
          .then((data: any) => {
            data.data.forEach((dataMap, index) => {
              dataMap.key = index;
              dataMap.salesOrderNo = dataMap.documentNumber;
              dataMap.salesOrderId = dataMap.id;
              dataMap.total = currencyFormat(dataMap.total);
              dataMap.date = dataMap.date
                ? moment(dataMap.date).format(DEFAULT_DATE_FORMAT)
                : "-";
              dataMap.salesOrderLines = dataMap.salesOrderLines.length
                ? dataMap.salesOrderLines
                : [];
              dataMap.salesOrderLines?.forEach((element, key) => {
                element.key = key;
                element.partNumberUnitCode = element.productCode
                  ? element.productCode
                  : element.assetNo;
                element.partName = element.productName;
                element.vat = element.taxValue;
                element.discount = element.discountValue;
              });
            });
            this.dataSource = data.data;
            this.totalElements = data.totalElements;
          })
          .finally(() => (this.loadingFind = false));
      });
    } catch (error) {
      this.$message.error(Messages.PROCESS_FAIL);
    }
  }

  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.findData(true);
  }

  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.findData(true);
  }
  onRangePickerChange(dates: Array<any>): void {
    if (!dates.length) {
      this.modelForm.date = undefined;
    }
  }

  handleClickEdit(data): void {
    const status: SALES_ORDER_STATUS = data.data.states as SALES_ORDER_STATUS;
    const toEdit: boolean =
      status === SALES_ORDER_STATUS.DRAFT ||
      status === SALES_ORDER_STATUS.SUBMITTED;
    if (toEdit) {
      this.$router.push({
        name: "sales.sales-order.edit",
        params: {
          id: data.data.salesOrderId,
        },
      });
    } else {
      this.$router.push({
        name: "sales.sales-order.detail",
        params: {
          id: data.data.salesOrderId,
        },
      });
    }
  }
}
